import { Link } from 'gatsby';
import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';
import { Device, Responsive } from '../../../styles/theme/responsive';

export interface StyledLinkProps {
  image: string;
}

// Posts styled
export const PostsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  > *:not(:last-child) {
    margin-right: ${getMargin('base')};
  }

  @media ${Responsive.lessThan(Device.mobile)} {
    width: 100%;
    > *:not(:last-child) {
      margin-bottom: ${getMargin('baseMobile')};
      margin-right: 0;
    }
    flex-direction: column;
    align-items: center;
  }
`;

// Post styled
export const Wrapper = styled.div`
  flex: 1;
  background-color: white;

  @media ${Responsive.lessThan(Device.mobile)} {
    width: 100%;
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 500px;
  background: url('${(props) => props.image}') no-repeat center center;
  background-size: cover;
  padding: ${getMargin('base')};

  @media ${Responsive.lessThan(Device.tablet)} {
    height: 250px;
  }
`;

export const Title = styled.p`
  bottom: 100px;
  font-weight: bold;
  font-size: 20px;
  color: white;
  justify-content: none;
`;

export const SubTitle = styled.span`
  bottom: 80px;
  font-size: 14px;
  color: white;
  justify-content: none;

  > *:not(:last-child):after {
    content: '/';
    font-size: 13px;
    font-weight: 500;
    padding: 0 10px 0 10px;
  }
`;

export const Description = styled.p`
  color: white;
  justify-content: none;
  @media ${Responsive.lessThan(Device.tablet)} {
    display: none;
  }
`;

export const Content = styled.p`
  display: flex;
  flex-direction: column;
`;

export const Date = styled.p`
  background-color: white;
  width: 50px;
  height: 50px;
  padding: 5px;
  margin: 0;
  text-align: center;
`;
