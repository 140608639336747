import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';

// AboutsMe styled
export const AboutsMeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

// AboutMe styled
export const Wrapper = styled.div`
  width: 300px;
  margin-right: ${getMargin('base')};
`;

export const Title = styled.h2`
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

export const Subtitle = styled.p`
  font-size: 14px;
`;
