import React from 'react';

import { Portfolio, PortfolioProps } from './Portfolio';
import { PortfoliosWrapper } from './styled';

export interface PortfoliosProps {
  portfolios: PortfolioProps[];
}

export const Portfolios: React.FC<PortfoliosProps> = ({ portfolios }) => {
  return (
    <PortfoliosWrapper>
      {portfolios.map((portfolio) => (
        <Portfolio image={portfolio.image} subtitle={portfolio.subtitle} />
      ))}
    </PortfoliosWrapper>
  );
};
