import React from 'react';
import ReactHeroCarousel from 'react-hero-carousel';

export interface HeroCarouselProps {
  images: string[];
}

export const HeroCarousel: React.FC<HeroCarouselProps> = ({ images }) => {
  return (
    <ReactHeroCarousel>
      {images.map((image) => (
        <img alt="carousel" key={image} src={image} width="100%" height="100%" />
      ))}
    </ReactHeroCarousel>
  );
};
