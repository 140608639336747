import { Link } from 'gatsby';
import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';
import { Device, Responsive } from '../../../styles/theme/responsive';

export interface StyledLinkProps {
  image: string;
}

// Portfolio styled
export const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 ${getMargin('base')} ${getMargin('base')} 0;

  @media ${Responsive.lessThan(Device.tablet)} {
    width: 250px;
    margin: 0 ${getMargin('baseMobile')} ${getMargin('base')} ${getMargin('baseMobile')};
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  width: 100%;
  height: 650px;

  background: url('${(portfolios) => portfolios.image}') no-repeat center center;
  background-size: cover;
  padding: 35px;

  :hover {
    opacity: 60%;
  }
`;

// Portfolios styled
export const PortfoliosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ${Responsive.lessThan(Device.mobile)} {
    flex-direction: column;
  }
`;
