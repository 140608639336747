import React from 'react';

import { StyledLink, SubTitle, Title, Wrapper } from './styled';

export interface GalleryTileProps {
  image: string;
  title: string;
  subTitle: string;
  category: string;
}

export const GalleryTile: React.FC<GalleryTileProps> = ({ image, title, subTitle, category }) => {
  return (
    <Wrapper>
      <StyledLink to="/" title="galérie" image={image}>
        <Title>{title}</Title>
        <SubTitle>
          {subTitle} {category}
        </SubTitle>
      </StyledLink>
    </Wrapper>
  );
};
