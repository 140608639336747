import React from 'react';

import { Post, PostProps } from './Post';
import { PostsWrapper } from './styled';

export interface PostsProps {
  posts: PostProps[];
  limit?: number;
}

export const Posts: React.FC<PostsProps> = ({ posts, limit }) => {
  return (
    <PostsWrapper>
      {posts
        .map((post) => (
          <Post
            image={post.image}
            date={post.date}
            title={post.title}
            subTitle={post.subTitle}
            category={post.category}
            description={post.description}
            link={post.link}
          />
        ))
        .slice(0, limit)}
    </PostsWrapper>
  );
};
