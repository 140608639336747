import React from 'react';

import { SubTitle } from '../../atoms/SubTitle/SubTitle';

import { StyledLink, Wrapper } from './styled';

export interface PortfolioProps {
  image: string;
  subtitle: string;
}

export const Portfolio: React.FC<PortfolioProps> = ({ image, subtitle }) => {
  return (
    <Wrapper>
      <SubTitle subTitle={subtitle} />
      <StyledLink to="/" title="Blog" image={image} />
    </Wrapper>
  );
};
