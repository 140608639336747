import { Link } from 'gatsby';
import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';
import { Device, Responsive } from '../../../styles/theme/responsive';

export interface StyledLinkProps {
  image: string;
}

// GalleryTile styled
export const Wrapper = styled.div`
  display: flex;
  margin: 0 ${getMargin('base')} ${getMargin('base')} 0;

  @media ${Responsive.lessThan(Device.tablet)} {
    width: 250px;
    margin: 0 ${getMargin('baseMobile')} ${getMargin('base')} ${getMargin('baseMobile')};
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  text-align: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 240px;

  background: url('${(galleryTiles) => galleryTiles.image}') no-repeat center center;
  background-size: cover;
  padding: 35px;

  :hover {
    opacity: 60%;
  }
`;

export const Title = styled.p`
  margin: 0;
  color: white;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
`;

export const SubTitle = styled.span`
  color: white;
  font-size: 12px;
  line-height: 18px;
`;

// GalleryTiles styled
export const GalleryTilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
