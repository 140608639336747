import React from 'react';

import { AboutsMeWrapper, Subtitle, Title, Wrapper } from './styled';

export interface AboutMeProps {
  aboutsMe: {
    title: string;
    subtitle: string;
  }[];
}

export const AboutMe: React.FC<AboutMeProps> = ({ aboutsMe }) => {
  return (
    <AboutsMeWrapper>
      {aboutsMe.map((aboutMe) => (
        <Wrapper>
          <Title>{aboutMe.title}</Title>
          <Subtitle>{aboutMe.subtitle}</Subtitle>
        </Wrapper>
      ))}
    </AboutsMeWrapper>
  );
};
