import React from 'react';

import { GalleryTile, GalleryTileProps } from './GalleryTile';
import { GalleryTilesWrapper } from './styled';

export interface GalleryTilesProps {
  galleryTiles: GalleryTileProps[];
  limit: number;
}

export const GalleryTiles: React.FC<GalleryTilesProps> = ({ galleryTiles, limit }) => {
  return (
    <GalleryTilesWrapper>
      {galleryTiles
        .map((galleryTile) => (
          <GalleryTile
            image={galleryTile.image}
            title={galleryTile.title}
            subTitle={galleryTile.subTitle}
            category={galleryTile.category}
          />
        ))
        .slice(0, limit)}
    </GalleryTilesWrapper>
  );
};
