import { graphql } from 'gatsby';
import { getSrc, ImageDataLike } from 'gatsby-plugin-image';
import * as React from 'react';

import Layout from '../components/Layout';
import { Button } from '../components/atoms/Buttons';
import { AboutMe } from '../components/molecules/AboutMe/AboutMe';
import { GalleryTiles } from '../components/molecules/GalleryTile/GalleryTiles';
import { HeroCarousel } from '../components/molecules/HeroCarousel';
import { PageSection } from '../components/molecules/PageSection';
import { ButtonWrapper } from '../components/molecules/PageSection/styled';
import { Portfolios } from '../components/molecules/Portfolios/Portfolios';
import { Posts } from '../components/molecules/Posts';

interface IndexQuery {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        description: string;
        carousel: Array<{
          title: string;
          subtitle: string;
          image: ImageDataLike;
        }>;
        aboutMe: {
          title: string;
          items: Array<{
            title: string;
            subtitle: string;
          }>;
        };
        portfolios: {
          title: string;
          subtitle: string;
          items: Array<{
            subtitle: string;
            image: ImageDataLike;
          }>;
        };
        galleryTiles: {
          title: string;
          subtitle: string;
          limit: number;
          items: Array<{
            image: ImageDataLike;
            title: string;
            subTitle: string;
            category: string;
            link?: string;
          }>;
        };
        contact: {
          title: string;
          subtitle: string;
        };
      };
    };
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: {
          fields: {
            slug: string;
          };
          frontmatter: {
            title: string;
            featuredimage: ImageDataLike;
            description: string;
            category: string;
            date: string;
            tags: string[];
          };
        };
      }>;
    };
  };
}

const IndexPage = ({ data }: IndexQuery) => {
  const { carousel, aboutMe, portfolios, galleryTiles, contact } = data.markdownRemark.frontmatter;
  const blogPosts = data.allMarkdownRemark.edges;

  const carouselImages = carousel.map((item) => getSrc(item.image) ?? '');

  const posts = blogPosts.map((post) => ({
    image: getSrc(post.node.frontmatter.featuredimage) ?? '',
    date: new Date(post.node.frontmatter.date),
    title: post.node.frontmatter.title,
    subTitle: post.node.frontmatter.tags?.length > 0 ? post.node.frontmatter.tags[0] : '',
    category: post.node.frontmatter.category,
    description: post.node.frontmatter.description,
    link: post.node.fields.slug,
  }));

  return (
    <Layout>
      <HeroCarousel images={carouselImages} />
      <main>
        {aboutMe && (
          <PageSection title={aboutMe.title} withSection>
            <AboutMe
              aboutsMe={aboutMe.items.map((item) => ({
                title: item.title,
                subtitle: item.subtitle,
              }))}
            />
          </PageSection>
        )}

        {portfolios && (
          <PageSection
            title={portfolios.title}
            subTitle={portfolios.subtitle}
            withSection
            backgroundColor="white"
          >
            <Portfolios
              portfolios={portfolios.items.map((item) => ({
                subtitle: item.subtitle,
                image: getSrc(item.image) ?? '',
              }))}
            />
          </PageSection>
        )}

        {galleryTiles && (
          <PageSection title={galleryTiles.title} subTitle={galleryTiles.subtitle} withSection>
            <GalleryTiles
              limit={galleryTiles.limit}
              galleryTiles={galleryTiles.items.map((item) => ({
                image: getSrc(item.image) ?? '',
                title: item.title,
                subTitle: item.subTitle,
                category: item.category,
              }))}
            />
          </PageSection>
        )}

        <PageSection title="Blog" withSection backgroundColor="white">
          <Posts posts={posts} limit={3} />
          <ButtonWrapper>
            <Button to="blog" children="Voir +" key={Button.name} />
          </ButtonWrapper>
        </PageSection>

        {contact && (
          <PageSection title={contact.title} subTitle={contact.subtitle} withSection>
            <ButtonWrapper>
              <Button to="contact" children="Contact" key={Button.name} />
            </ButtonWrapper>
          </PageSection>
        )}
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
        carousel {
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        aboutMe {
          title
          items {
            title
            subtitle
          }
        }
        portfolios {
          title
          subtitle
          items {
            subtitle
            image {
              childImageSharp {
                gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        galleryTiles {
          title
          subtitle
          limit
          items {
            title
            subTitle
            category
            image {
              childImageSharp {
                gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        contact {
          title
          subtitle
        }
      }
    }
    allMarkdownRemark(
      limit: 6
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(blog)/" } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            tags
            category
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
