import React from 'react';

import { dateMonth } from '../../../utils/date.utils';
import { toExtract } from '../../../utils/string';

import { Content, Date, Description, StyledLink, SubTitle, Title, Wrapper } from './styled';

export interface PostProps {
  image: string;
  date: Date;
  title: string;
  subTitle: string;
  category: string;
  description: string;
  link: string;
}

export const Post: React.FC<PostProps> = ({
  image,
  title,
  subTitle,
  date,
  category,
  description,
  link,
}) => {
  const formattedDate = dateMonth(date);
  return (
    <Wrapper>
      <StyledLink to={link} title="Blog" image={image}>
        <Date>{formattedDate}</Date>
        <Content>
          <Title>{title}</Title>
          <SubTitle>
            <span>{subTitle}</span>
            <span>{category}</span>
          </SubTitle>
          <Description>{toExtract(description, 200)}</Description>
        </Content>
      </StyledLink>
    </Wrapper>
  );
};
